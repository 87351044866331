import React from 'react';
// import { captureException } from '@sentry/gatsby';
import loadable from '@loadable/component';

const HeroMedia = ({ component, playVideo }) => {
  const media = component?.reference[0];
  if (!media) return null;

  switch (media.__typename) {
    case 'DatoCmsWebsiteImage': {
      const HeroImage = loadable(() => import('./HeroImage'));
      return <HeroImage component={media} />;
    }
    case 'DatoCmsWebsiteVideo': {
      const HeroVideo = loadable(() => import('./HeroVideo'));
      return <HeroVideo component={media} playVideo={playVideo} />;
    }
    case 'DatoCmsWebsiteForm': {
      const HeroDemoForm = loadable(() => import('../../Forms/FormNew'));
      return <HeroDemoForm formInfo={media} />;
    }
    case 'DatoCmsComponentTestimonial': {
      const HeroReviewCard = loadable(() => import('./HeroReviewCard'));
      return <HeroReviewCard testimonial={media} />;
    }
    default:
      console.log('Hero reference type not found');
      return null;
  }
};

export default HeroMedia;
